<template>
  <v-card v-if="instantie">
    <v-toolbar color="#6497b1" dark  flat dense>
      <v-toolbar-title>{{ instantie.naam }}</v-toolbar-title>

        <v-spacer></v-spacer>
   
        <template v-slot:extension>
        <v-tabs>
          <v-tab :to="{ name: 'Audits' }">Audits</v-tab>
          <v-tab :to="{ name: 'Gebruikers' }">Gebruikers</v-tab>
        </v-tabs>
        </template>
  

    </v-toolbar>


    <router-view></router-view>
  </v-card>
</template>

<script>

import client from "api-client";

export default {
  name: "SelectedInstantie",
  data: () => ({
    instantie: null
  }),
  watch: {
    "$route.params.instantieId": {
      handler: function (instantieId) {
        this.getInstantie(instantieId)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getInstantie(instantieId) {
      client.fetchInstantie(instantieId).then((data) => {
        this.instantie = data;
      });
    }
  }
};
</script>